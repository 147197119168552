<template>
    <b-modal v-model="openUserModal" hide-footer no-close-on-backdrop @hidden="onHiding">
        <template #modal-title>
            I’m Here to Help You
        </template>
        <form ref="form" @submit.stop.prevent="handleSubmit">
            <!-- <h4>Please provide your info to view procedures & request help.</h4> -->
            <b-form-group label="What is Your Name?" label-for="name-input">
                <b-form-input id="name-input" placeholder="enter your name" v-model="currentUser.name"></b-form-input>
            </b-form-group>

            <b-form-group label="I Need Your Mobile Phone Number To Speak To You" label-for="mobile-input">
                <b-form-input class="mb-2" id="mobile-input" placeholder="enter your mobile number"
                    v-model="currentUser.mobile"></b-form-input>
                <label>We will notify you in-case of an emergency.</label>
            </b-form-group>

            <button type="button" class="btn btn-primary pull-right" @click="handleOk">Submit</button>
        </form>
    </b-modal>
</template>

<script>

import {
    externalData
} from '@/src/state/api/pwaexternal';

import {
    authenticateData
} from '@/src/state/api/authenticate';
import messageSound from '@assets/sound/message-received.mp3';
export default {
    components: {},
    props: {
        openModal: {
            type: Boolean,
            required: false,
            default: () => false,
        }
    },
    watch: {
        openModal: {
            immediate: true,
            deep: true,
            handler(newValue, oldValue) {
                this.openUserModal = newValue;
            }
        }
    },
    computed: {
        locationId() {
            return this.$route.params.locationId;
        },
    },
    data() {
        return {
            openUserModal: false,
            currentUser: {},
            currentLocation: null,
        }
    },
    async created() {
        console.log("🚀 ~ loadLocation ~ this.id:", this.locationId)
        await this.loadLocation()
    },
    methods: {
        async loadLocation() {
            this.currentLocation = await externalData.searchLocationByCode({
                qrCode: this.locationId,
                locationId: this.locationId
            })               
        },
        resetModal() {
            this.currentUser = {};
        },
        onHiding() {
            this.$emit("closeMe");
        },
        handleOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            if (!this.currentUser.name || !this.currentUser.mobile) {
                this.$vs.notify({
                    title: "Error",
                    text: "Please enter your name and mobile number",
                    color: "danger",
                    position: "top-center"
                });
                bvModalEvent.preventDefault()
                return false;
            }
            this.handleSubmit()
            new Audio(messageSound).play();
        },
        async handleSubmit() {
            var result = await authenticateData.createUser({
                mobileNumber: this.currentUser.mobile,
                name: this.currentUser.name,
                requestDateTime: new Date(),
                ownerCompanyId: this.currentLocation.companyKeyId,
                locationId: this.currentLocation.keyId
            })
            if (result.succeeded) {
                this.$store.dispatch("setLoggedInUser", {
                    ...this.currentUser,
                    token: result.data.token
                });

                this.$vs.notify({
                    title: "",
                    text: "Record updated",
                    color: "success",
                    position: "top-center"
                });

                this.$emit("closeMe");
            }
        },
    }
}
</script>