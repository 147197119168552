<template>
    <div class="container-fluid mt-2" :key="componentKey">
        <template v-if="!currentIncidentId">
            <past-incident></past-incident>
        </template>
        <template v-if="currentIncidentId">
            <conversation :incidentId="currentIncidentId"></conversation>
        </template>
        <prompt-user :openModal="openUserModal" @closeMe="closeNewUser"></prompt-user>
    </div>
</template>

<script>

import pastIncident from "./pastIncident"
import conversation from "./conversation"
import promptUser from '../common/promptUser'
import {
    authenticateData
} from '@/src/state/api/authenticate';
import {
    externalData
} from '@/src/state/api/pwaexternal';

export default {
    data() {
        return {
            currentDateTime: new Date(),
            newMessage: "",
            conversationList: [],
            currentIncidentId: null,
            openUserModal: false,
            componentKey: 1
        }
    },
    components: {
        pastIncident,
        conversation,
        promptUser
    },
    computed: {
        locationId() {
            return this.$route.params.locationId;
        },
        incidentId() {
            return this.$route.params.incidentId;
        },
    },
    watch: {
        $route(to, from) {
            if (to.path !== from.path) {
                this.currentIncidentId = this.incidentId;
            }
        }
    },
    async created() {
        await this.loadLocation()
        await this.loadUser();
    },
    methods: {
        async loadUser() {
            if (this.$store.getters.loggedInUser === null) {
                this.openUserModal = true;
            } else {
                await this.createUser();
            }
        },
        async loadLocation() {
            this.currentLocation = await externalData.searchLocationByCode({
                qrCode: this.locationId,
                locationId: this.locationId
            })
            if (this.currentLocation) {
                this.mustHaveUser = this.currentLocation.mustHaveUser;
                this.$store.dispatch("setLoggedInLocation", this.currentLocation);

                if (this.mustHaveUser && (!this.$store.getters.loggedInUser)) {
                    this.openUserModal = true;
                }
            }
        },
        async createUser() {
            var result = await authenticateData.createUser({
                mobileNumber: this.$store.getters.loggedInUser.mobile,
                name: this.$store.getters.loggedInUser.name,
                requestDateTime: new Date(),
                ownerCompanyId: this.currentLocation.companyKeyId,
                locationId: this.currentLocation.keyId
            })
            if (result.succeeded) {
                this.$store.dispatch("setLoggedInUser", {
                    ...this.$store.getters.loggedInUser,
                    token: result.data.token
                });
            }
        },        
        async closeNewUser() {
            await this.loadUser();
            this.componentKey++;
            this.openUserModal = false
        }
    }
}
</script>

<style>
#chat-form {
    position: fixed;
    left: 0;
    bottom: 60px;
    width: 100%;
    background-color: #dddddd;
    color: white;
    text-align: center;
    padding: 10px;
}
</style>